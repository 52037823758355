<footer class="main-footer2">
    <div class="list-info">
        <div class="container">
            <div class="row">
                <div class="col-sm-3"><img data-v-9e928f9a="" src="https://framework-gb.cdn.gob.mx/gobmx/img/logo_blanco.svg" href="/" alt="logo gobierno de méxico" class="logo_footer" style="max-width: 90%;"></div>
                <div class="col-sm-3">
                    <h5>Enlaces</h5>
                    <ul>
                        <li><a href="https://participa.gob.mx" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Participa</a></li>
                        <li><a href="https://www.gob.mx/publicaciones" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Publicaciones Oficiales</a></li>
                        <li><a href="http://www.ordenjuridico.gob.mx" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Marco Jurídico</a></li>
                        <li><a href="https://consultapublicamx.inai.org.mx/vut-web/" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Plataforma Nacional de Transparencia</a></li>
                        <li><a href="https://alertadores.funcionpublica.gob.mx/" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Alerta</a></li>
                        <li><a href="https://sidec.funcionpublica.gob.mx/" target="_blank" rel="noopener" title="Enlace abre en ventana nueva">Denuncia</a></li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h5>¿Qué es gob.mx?</h5>
                    <p style="color:#ffff;">Es el portal único de trámites, información y participación ciudadana.
                        <br>
                        <a href="https://www.gob.mx/que-es-gobmx">Leer más</a></p>
                    <ul>
                        <li><a href="https://datos.gob.mx">Portal de datos abiertos</a></li>
                        <li><a href="https://www.gob.mx/accesibilidad">Declaración de accesibilidad</a></li>
                        <li><a href="https://www.gob.mx/privacidadintegral">Aviso de privacidad integral</a></li>
                        <li><a href="https://www.gob.mx/privacidadsimplificado">Aviso de privacidad simplificado</a></li>
                        <li><a href="https://www.gob.mx/terminos">Términos y Condiciones</a></li>
                        <li><a href="https://www.gob.mx/terminos#medidas-seguridad-informacion">Política de seguridad</a></li>
                        <li><a href="https://www.gob.mx/sitemap">Mapa de sitio</a></li>
                    </ul>
                </div>
                <div class="col-sm-3">
                    <h5><a href="https://www.gob.mx/tramites/ficha/presentacion-de-quejas-y-denuncias-en-la-sfp/SFP54">Denuncia contra servidores públicos</a></h5>
                    <h5>Síguenos en</h5>

                    <div class="row">
                        <div>
                            <span><a style="color:#ffff;" target="_blank" rel="noopener" title="Enlace abre en ventana nueva" href="https://twitter.com/GobiernoMX" aria-label="Twitter de presidencia"><i class="fab fa-twitter"></i></a></span>
                            <span class="mx-3"><a style="color:#ffff;" target="_blank" rel="noopener" title="Enlace abre en ventana nueva" href="https://www.facebook.com/gobmexico" aria-label="Facebook de presidencia"><i class="fab fa-facebook"></i></a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid footer-pleca2">
        <div class="row">
            <div class="col"><br><br></div>
        </div>
    </div>
</footer>