import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss']
})
export class ModalInfoComponent implements OnInit {
  title: string | null = null;
  url: string | null = 'https://www.youtube.com/embed/zlhDpZdmQP0?autoplay=1&mute=1';



  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void {
    
  } 
}
