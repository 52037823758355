<app-header></app-header>
<div class="container mb-5">
    <h2 class="text-center m-5">Dirección de Medios Audiovisuales e Informáticos <br>
        Aviso de privacidad integral</h2>

    <p>
        <b>La identidad y domicilio del responsable</b><br>
        Dirección General de Materiales Educativos<br>
        Avenida Universidad No.1200, Col. Xoco, Delegación Benito Juárez, C.P: 03330, Ciudad de
        México.
    </p>
    <p>
        <b>Para qué serán tratados los datos personales</b><br>
        Los datos personales que recabamos de usted, serán usados exclusivamente para las
        atribuciones propias de la Dirección General de Materiales Educativos, establecidas en el
        Artículo 30 del Reglamento Interior de la Secretaría de Educación Pública.
        De manera adicional, utilizaremos su información personal para las siguientes finalidades
        que <b>no son necesarias</b> para el servicio solicitado, pero que nos permiten y facilitan brindarle
        una mejor atención:
    </p>

    <ul>
        <li>Validar solicitudes de información.</li>
        <li>Envío de comunicación oficial.</li>
        <li>Difundir convocatorias.</li>
        <li>Difundir información sobre nuevos materiales educativos.</li>
        <li>Realizar encuestad de opinión sobre nuestros servicios.</li>
        <li>Para la creación, estudio, análisis, actualización y conservación de los materiales educativos.</li>
    </ul>

    <p>
        <b>Los mecanismos para que el titular pueda manifestar su negativa para finalidades secundarias o accesorias</b><br>
        En caso de que no desee que sus datos personales se utilicen para estos fines, indíquelo a través del correo electrónico: <b>telesecundaria@nube.sep.gob.mx</b> <br>
        La negativa para el uso de sus datos personales para estas finalidades no podrá ser unmotivo para que le neguemos los servicios y productos que solicita.
    </p>

    <p>
        <b>Los datos personales tratados</b><br>
        Para llevar a cabo lasfinalidades descritas en el presente aviso de privacidad, utilizaremos
        los siguientes datos personales: nombre completo, edad; sexo; domicilio, teléfono y correo
        electrónico particulares; fotografía; nombre de la escuela donde trabaja, grado en el que
        labora, CCT, domicilio, número telefónico y correo electrónico laborales.
        Se informa que no se recabarán datos personales sensibles.

    </p>

    <p>
        <b>Transferencia de datos personales</b><br>
        No se realizarán transferencias de datos personales, salvo aquéllas que sean necesarias para
        atender requerimientos de información de una autoridad competente, que esté
        debidamente fundados y motivados.
    </p>

    <p>
        <b>Los medios y el procedimiento para ejercer los derechos ARCO</b><br>
        Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los
        utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho
        solicitar la corrección de su información personal en caso de que esté desactualizada, sea
        inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de
        datos cuando considere que la misma no está siendo utilizada conforme a los principios,
        deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso
        de sus datos personales para fines específicos(Oposición). Estos derechosse conocen como
        derechos ARCO
    </p>

    <p>
        El titular de los datos podrá ejercer sus derechos ARCO personalmente ante la Dirección
        General de Materiales Educativos ubicada en <b>Avenida Universidad No.1200, Col. Xoco,
            Delegación Benito Juárez, C.P: 03330, Ciudad de México.</b>
        o bien, por medio del correo electrónico: <b>telesecundaria@nube.sep.gob.mx</b>
    </p>

    <p>
        <b>Uso de Cookies</b><br>
        Gob.mx utiliza cookies para ayudar a obtener información que nos permite mejorar tu
        experiencia como usuario y brindarte un mejor servicio.
        En cualquier momento puede eliminar las cookies accediendo a las preferencias de
        configuración de su navegador.
    </p>

    <p>
        <b>Cambios al aviso de privacidad</b><br>
        En caso de que exista un cambio de este aviso de privacidad, lo haremos de tu conocimiento
        en el propio portal <a href="https://telesecundaria.sep.gob.mx/" target="_blank">telesecundaria.sep.gob.mx</a>
    </p>

</div>
<app-footer></app-footer>
