import { Component } from '@angular/core';

@Component({
  selector: 'app-politicas-p',
  templateUrl: './politicas-p.component.html',
  styleUrls: ['./politicas-p.component.scss']
})
export class PoliticasPComponent {

}
