<div class="modal-header md_custom"> <!--class="stylish-color-dark"-->
  <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true" class="white-text">&times;</span>
  </button>
  <h4 class="heading lead " id="myModalLabel">Libro Digital Interactivo</h4>
</div>
<div class="modal-body">
  <div class="container mt-5">
      <h2 class="text-center">{{gradoSel[0].g}} : {{perfilSel[0].p}}</h2>

      <section *ngIf="perfilSel[0].id_p == 2">
        <blockquote class="blockquote bq-warning">
            <p class="bq-title">Bloque 1</p>
        </blockquote>
  
        <swiper [config]="config">
            <div class="swiper-wrapper mb-5">
                <mdb-card cascade="true" narrower="true" class="swiper-slide amp bloque1 ldih" *ngFor="let dat of ldi | lidpipe:perfilSel[0].id_p:gradoSel[0].id_g:1" style="margin-bottom: 5px;">
                    <!--Card image-->
                    
                    <div class="view view-cascade overlay waves-light" mdbWavesEffect style="margin-top: 0.75rem!important; height: 200px;">
                        <mdb-card-img src="{{dat.img}}"></mdb-card-img>
                        <a href="{{dat.url}}" target="_blank">
                            <div class="mask rgba-white-slight"></div>
                        </a>
                    </div>
                    
                    <!--/Card image-->
                    <mdb-card-body cascade="true" class="text-center">
                        <!--Text-->
                        <h5 class="card-title text-white mt-4">{{dat.asignatura}}</h5>
                        
                        <a href="{{dat.url}}" target="_blank"><mdb-icon fas icon="play-circle" size="3x" style="color: white;" class="mt-1"></mdb-icon></a>
  
                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </swiper>

        <blockquote class="blockquote bq-warning">
          <p class="bq-title">Bloque 2</p>
        </blockquote>

        <swiper [config]="config">
            <div class="swiper-wrapper mb-5">
                <mdb-card cascade="true" narrower="true" class="swiper-slide amp bloque2 ldih" *ngFor="let dat of ldi | lidpipe:perfilSel[0].id_p:gradoSel[0].id_g:2" style="margin-bottom: 5px;">
                    <!--Card image-->
                    <div class="view view-cascade overlay waves-light" mdbWavesEffect style="margin-top: 0.75rem!important; height: 200px;">
                        <mdb-card-img src="{{dat.img}}"></mdb-card-img>
                        <a href="{{dat.url}}" target="_blank">
                            <div class="mask rgba-white-slight"></div>
                        </a>
                    </div>
                    <!--/Card image-->
                    <mdb-card-body cascade="true" class="text-center">
                        <h5 class="card-title text-white">{{dat.asignatura}}</h5>
                        <!--Text-->
                        <a href="{{dat.url}}" target="_blank">
                          <mdb-icon fas icon="play-circle" size="3x" style="color: white;"></mdb-icon>

                        </a>

                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </swiper>

        <blockquote class="blockquote bq-warning">
          <p class="bq-title">Bloque 3</p>
        </blockquote>

        <swiper [config]="config">
            <div class="swiper-wrapper mb-3">
                <mdb-card cascade="true" narrower="true" class="swiper-slide amp bloque3 ldih" *ngFor="let dat of ldi | lidpipe:perfilSel[0].id_p:gradoSel[0].id_g:3" style="margin-bottom: 5px;">
                    <!--Card image-->
                    <div class="view view-cascade overlay waves-light" mdbWavesEffect style="margin-top: 0.75rem!important; height: 200px;">
                        <mdb-card-img src="{{dat.img}}"></mdb-card-img>
                        <a href="{{dat.url}}" target="_blank">
                            <div class="mask rgba-white-slight"></div>
                        </a>
                    </div>
                    <!--/Card image-->
                    <mdb-card-body cascade="true" class="text-center">
                        <h5 class="card-title text-white">{{dat.asignatura}}</h5>
                        <!--Text-->
                        <a href="{{dat.url}}" target="_blank">
                          <mdb-icon fas icon="play-circle" size="3x" style="color: white;"></mdb-icon>

                        </a>

                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </swiper>

      </section>

      <section *ngIf="perfilSel[0].id_p == 1">
        
        <swiper [config]="config">
            <div class="swiper-wrapper">
                <mdb-card cascade="true" narrower="true" class="swiper-slide amp bloquemaestro ldih" *ngFor="let dat of ldi | lidpipe:perfilSel[0].id_p:gradoSel[0].id_g:0" style="margin-bottom: 5px;">
                    <!--Card image-->
                    <div class="view view-cascade overlay waves-light" mdbWavesEffect style="margin-top: 0.75rem!important; height: 200px;">
                        <mdb-card-img src="{{dat.img}}"></mdb-card-img>
                        <a href="{{dat.url}}" target="_blank">
                            <div class="mask rgba-white-slight"></div>
                        </a>
                    </div>
                    <!--/Card image-->
                    <mdb-card-body cascade="true" class="text-center">
                        <h5 class="card-title text-black">{{dat.asignatura}}</h5>
                        <!--Text-->
                        <a href="{{dat.url}}" target="_blank">
                          <mdb-icon fas icon="play-circle" size="3x" style="color: black;"></mdb-icon>

                        </a>

                    </mdb-card-body>
                    <!--/.Card content-->
                </mdb-card>
                <!--/.Card-->
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </swiper>

      </section>
  </div>
</div>