<!--Navbar-->
<mdb-navbar SideClass="inverse2 navbar navbar-expand-lg navbar-dark" [containerInside]="true">

    <!-- Navbar brand -->
    <mdb-navbar-brand>
        <a class="navbar-brand" href="https://www.gob.mx/"><img src="https://framework-gb.cdn.gob.mx/gobmx/img/logo_blanco.svg" width="80" height="auto" alt="Página de inicio, Gobierno de México"></a>

    </mdb-navbar-brand>

    <!-- Collapsible content -->
    <links>


        <ul class="navbar-nav ml-auto nav-flex-icons">
            <li class="nav-item">
                <a class="nav-link" href="https://www.gob.mx/tramites">
                Trámites
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://www.gob.mx/gobierno">
                    Gobierno
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://www.gob.mx/busqueda">
                    <mdb-icon fab icon="searchengin"></mdb-icon>
                </a>
            </li>

        </ul>
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
<!--/.Navbar-->