import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { PoliticasPComponent } from './politicas/politicas-p/politicas-p.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'politicas_privacidad', component: PoliticasPComponent },
  { path: 'MaterialesE',
    loadChildren:()=> import('./materialedu/materialedu.module').then(m => m.MaterialeduModule)
  },
  //{path: 'MaterialesE2',
  //   loadChildren:()=> import('./materialedu2/materialedu2.module').then(m => m.Materialedu2Module)
  //},
  {path: 'MaterialesE3',
    loadChildren:()=> import('./materialedu3/materialedu3.module').then(m => m.Materialedu3Module)
  },
  {path: 'MaterialesE2',
    loadChildren:()=> import('./materialedu2/materialdos.module').then(m => m.MaterialdosModule)
  },
  { path: '**', redirectTo:'home' }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
